import axios from 'axios';
import { socket } from "@/socket.js";

const messages = {
    namespaced: true,

    state: {
        message: null,
    },
    getters: {},

    mutations: {
        set(state, newMessage) {
            if (newMessage) {
                newMessage.message = newMessage.message.replaceAll('\n', "<br>");
            }
            state.message = newMessage;
        },
        remove(state) {
            state.message = null;
        },
    },

    actions: {
        async fetch({ commit }) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/message`);
                commit('set', response.data)
            } catch (error) {
                commit('set', null)
                console.log(error)
            }
        },

        async listenInserted({ commit }) {
            socket.on("message-inserted", (message) => {
                commit('set', message)
            });
        },

        async listenRemoved({ commit }) {
            socket.on("message-removed", () => {
                commit('remove')
            });
        },
    },
}

export default messages;

// socket.off("message-inserted")
// socket.off("message-removed")
